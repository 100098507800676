// GLOBAL CSS APPLIED TO ALL PROJECTS
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '../../projects/tuula-common/src/styles/index.scss';
@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@import url('https://fonts.googleapis.com/css?family=Onest');
@import url('https://fonts.googleapis.com/css?family=Calibri');
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@500&display=swap');
@import '../../node_modules/tippy.js/dist/tippy.css';
@import '../../node_modules/tippy.js/themes/light.css';

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@include mat.all-component-densities(0);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$c4p-angular-boilerplate-primary: mat.m2-define-palette(
  mat.$m2-grey-palette,
  800
);
$c4p-angular-boilerplate-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);
// The warn palette is optional (defaults to red).
$c4p-angular-boilerplate-warn: mat.m2-define-palette(mat.$m2-red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$c4p-angular-boilerplate-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $c4p-angular-boilerplate-primary,
      accent: $c4p-angular-boilerplate-accent,
      warn: $c4p-angular-boilerplate-warn,
    ),
    typography: mat.m2-define-typography-config(),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($c4p-angular-boilerplate-theme);

// @include mat.all-component-themes($c4p-angular-boilerplate-theme);
@include mat.badge-theme($c4p-angular-boilerplate-theme);
@include mat.radio-theme($c4p-angular-boilerplate-theme);
@include mat.select-theme($c4p-angular-boilerplate-theme);
@include mat.button-theme($c4p-angular-boilerplate-theme);
@include mat.checkbox-theme($c4p-angular-boilerplate-theme);
@include mat.slide-toggle-theme($c4p-angular-boilerplate-theme);
@include mat.tabs-theme($c4p-angular-boilerplate-theme);
@include mat.button-toggle-theme($c4p-angular-boilerplate-theme);
@include mat.slider-theme($c4p-angular-boilerplate-theme);

@import 'ngx-toastr/toastr';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import "tuula-ui-color-palette";
@import "layout";
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v118/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

:root {

  --form-title-bg-color: #f3f4f7;
  --form-title-font-color: #303030;
  --form-title-line-color: #e4e4e4;
  --card-bg-color: #f3f4f7;
  --card-line-color: #e4e4e4;
  --card-shade-bg-color: #f5f6f9;
  --card-shade-bg-color2: #f7f8fb;
  --card-shade-font-color: #272727;
  --card-shade-line-color: #e4e4e4;
  --table-header-bg-color: #fbfcfd;
  --table-header-font-color: #404040;
  --form-bg-color: #ffffff;
  --form-font-color: #606060;
  --form-line-color: #efefef;
  --form-shade-bg-color: #f9fafc;
  --form-dynamic-bg-color: #fcfcfc;
  --master-bg-color: #f9fafc;
  --master-font-color: #272727;
  --master-line-color: #eaeaea;
  --top-bg-color: #ffffff;
  --top-font-color: #272727;
  --dark-border: #767676;
  --light-border: #f7f8fb;
  --warning-border: #e03e0d;

  --navbar-collapsed-width: 77px;
  --navbar-expanded-width: 230px;
  --navbar-mobile-breakpoint: 992px;



--form-nd-input-height:39px;


--form-nd-label-text-color:var(--gray-color-700);
--form-nd-label-font-size:14px;
--form-nd-label-font-weight:500;
--form-nd-label-line-height:20px;

--form-nd-inner-text-color:var(--gray-color-700);
--form-nd-inner-text-font-size:14px;
--form-nd-inner-text-font-weight:400;
--form-nd-inner-text-line-height:20px;



--form-nd-error-font-size:14px;
--form-nd-error-font-weight:400;
--form-nd-error-line-height:20px;

--form-nd-hint-text-color:var(--gray-color-600);
--form-nd-hint-font-size:14px;
--form-nd-hint-font-weight:400;
--form-nd-hint-line-height:20px;
}





$form-title-bg-color: var(--form-title-bg-color);
$form-title-line-color: var(--form-title-line-color);
$card-bg-color: var(--card-bg-color);
$card-line-color: var(--card-line-color);
$card-shade-bg-color: var(--card-shade-bg-color);
$card-shade-bg-color2: var(--card-shade-bg-color2);
$card-shade-font-color: var(--card-shade-font-color);
$card-shade-line-color: var(--card-shade-line-color);
$table-header-bg-color: var(--table-header-bg-color);
$table-header-font-color: var(--table-header-font-color);
$form-bg-color: var(--form-bg-color);
$form-font-color: var(--form-font-color);
$form-line-color: var(--form-line-color);
$form-shade-bg-color: var(--form-shade-bg-color);
$form-dynamic-bg-color: var(--form-dynamic-bg-color);
$master-bg-color: var(--master-bg-color);
$master-font-color: var(--master-font-color);
$master-line-color: var(--master-line-color);
$top-bg-color: var(--top-bg-color);
$top-font-color: var(--top-font-color);
$dark-border: var(--dark-border);
$light-border: var(--light-border);
$warning-border: var(--warning-border);

$action-icon-size: 16px;
$big-menu-icon-size: 18px;
$top-bar-icon-size: 20px;

$font-medium: 13px;
$font-large: 14px;
$font-xlarge: 18px;
$font-small: 11px;
$font-x-small: 9px;
$test-color: #30bbc5;




body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: $tuula-font-family;
}

.toast-title {
  font-weight: bold;
  font-size: 14px;
  padding-left: 5px;
}

.toast-message {
  word-wrap: break-word;
  font-size: 14px;
  padding-left: 5px;
}

// ***************************************************************************
// scroll
// ***************************************************************************
*::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

*::-webkit-scrollbar-track {
  background-color: var(--white);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--gray-color-300);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-color-400);
}

// ***************************************************************************
// general
// ***************************************************************************
.c4p-color-container {
  color: $master-font-color !important;
}

.c4p-flex {
  display: flex;
}


.c4p-content {
  padding-top: 10px;
}

.c4p-content-menu {
  float: right;
}

.c4p-tab-content {
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  height: calc(100% - 95px);
}

.c4p-tab-menu {
  position: absolute;
  float: right;
  overflow: hidden;
  right: 30px;
  top: -40px;
  // display: flex;
  // justify-content: space-between;
  // padding-left:30px;
  // padding-right:30px;
  // height: 40px;
  // margin-bottom: 5px;
  // background-color: $top-bg-color;
  // border: solid;
  // border-width: 0px 0px 1px 0px;
  // border-color: $master-line-color;
  z-index: 10;
}

.c4p-horizontal-divider {
  padding: 10px;
}

.c4p-vertical-divider {
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.c4p-underline {
  border: solid;
  border-width: 0px 0px 1px 0px;
  margin-bottom: 20px;
  padding-bottom: 6px;
  border-color: $master-line-color;
  width: 100%;
  display: block;
}

.c4p-form {
  background-color: $form-title-bg-color;
  color: $form-title-font-color;
  border-width: 1px;
  border-style: solid;
  border-color: $form-bg-color;
  border-radius: $border-radius;
  font-size: $font-medium;
  font-weight: 400;
  font-family: $tuula-font-family;
  margin: 5px 0px 5px 0px;
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
  // box-shadow: 0px 0px 1px black;
}

app-panel.white-background > div.c4p-form {
  background-color: white !important;
}
.c4p-popup-form {
  background-color: $form-title-bg-color;
  color: $form-title-font-color;
  border-width: 1px;
  border-style: solid;
  border-color: $form-bg-color;
  border-radius: $border-radius;
  font-size: $font-medium;
  font-weight: 400;
  font-family: $tuula-font-family;
  margin: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 90vh;
  max-width: 95vw;
}

.side-sheet-form{
  background-color: $form-title-bg-color;
  color: $form-title-font-color;
  border-width: 1px;
  border-style: solid;
  border-color: $form-bg-color;
  border-radius: $border-radius;
  font-size: $font-medium;
  font-weight: 400;
  font-family: Ubuntu;
  margin: 0px;
  overflow-y: auto;
  max-height: 90vh;
  max-width: 95vw;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100vh;
}

.cdk-overlay-pane .invoice-detail {
  max-height: 90vh;
  overflow: scroll;
}

.c4p-popup-form-large {
  @extend .c4p-popup-form;
  max-height: 90vh;
  max-width: 220vh;
  min-width: 180vh;
}

.c4p-popup-form-medium {
  @extend .c4p-popup-form;
  max-width: 110vh;
  max-height: 90vh;
}

.c4p-popup-form-small {
  @extend .c4p-popup-form;
  max-width: 80vh;
  max-height: 90vh;
}

.c4p-popup-form-share-and-sign {
  @extend .c4p-popup-form;
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}

.c4p-form-title {
  border-bottom: 1px solid var(--gray-color-200);
  background-color: $white;
  height: 30px;
  position: relative;
  padding: 8px 0px 8px 0px;
}

.c4p-form-row {
  height: 100%;
  border: solid;
  border-color: $light-border;
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: $form-bg-color;
  align-items: center;
}

.c4p-form-row-borderless {
  height: 100%;
  border: none;
  display: flex;
  flex-wrap: wrap;
  background-color: $form-bg-color;
  align-items: center;
}

.c4p-form-row-no-height {
  border: solid;
  border-color: $light-border;
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: $form-bg-color;
  align-items: center;
}

.checklist-form-row {
  border: solid;
  border-color: $light-border;
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: $form-bg-color;
  align-items: center;
}

.form-row {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.c4p-dynamic-form-row {
  border: solid;
  border-color: $light-border;
  border-width: 4px 4px 4px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: $form-bg-color;
  align-items: center;
}

.c4p-dynamic-form-row-group {
  border: solid;
  border-color: $light-border;
  border-width: 4px 4px 4px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: $form-dynamic-bg-color;
  align-items: center;
}

.c4p-form-row-center {
  height: 100%;
  border: solid;
  border-color: $light-border;
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $form-bg-color;
  align-items: center;
}

.c4p-form-row-right {
  height: 100%;
  border: solid;
  border-color: $light-border;
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  background-color: $form-bg-color;
  align-items: center;
  padding-right: 15px;
}

.c4p-form-row-center-borderless {
  height: 100%;
  border: solid;
  border-color: $light-border;
  border-width: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $form-bg-color;
  align-items: center;
}

.c4p-form-input {
  min-height: 75px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
}

.c4p-form-input-small {
  min-height: 35px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
}

.c4p-form-input-nowidth {
  height: 30px;
  margin-left: 8px;
  // margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.c4p-form-input-small-nowidth {
  height: auto;
  margin-left: 8px;
  vertical-align: middle;
  display: flex;
  align-items: flex-end;
}

.c4p-form-box {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  background-color: grey;
  border-radius: $border-radius;
  color: white;
  justify-content: center;
}

.c4p-form-box-red {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  background-color: red;
  border-radius: $border-radius;
  color: white;
  justify-content: center;
}

.c4p-form-input-big {
  min-height: 100px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
}

.c4p-form-input-full-width {
  min-height: 75px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.c4p-form-input-full-width-no-height {
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  color: $form-font-color;
}

.c4p-dynamic-form-input-small {
  min-height: 35px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.c4p-dynamic-form-input-normal {
  min-height: 35px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c4p-form-label {
  font-family: $tuula-font-family;
  font-size: $font-normal;
  color: $form-font-color;
}

.c4p-form-text-area {
  height: 100%;
  width: 100% !important;
  background-color: $form-bg-color !important;
  color: $form-font-color !important;
  border: solid !important;
  border-width: 0px 0px 1px 0px !important;
  border-radius: $border-radius;
  border-color: $form-line-color !important;
}

.c4p-form-text-area-gate-keeper {
  height: 100%;
  width: 100% !important;
  background-color: $form-shade-bg-color !important;
  color: $form-font-color !important;
  border: solid !important;
  border-width: 1px !important;
  border-color: $form-line-color !important;
}

.c4p-align-left {
  display: flex;
  align-items: center;
}

.c4p-align-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap:8px;
}

.c4p-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  word-wrap: normal;
  // padding-left: 8px;
}


// ***************************************************************************
// question set stepper
// ***************************************************************************
.c4p-form-questionset-stepper {
  height: 100%;
  border: solid;
  border-color: $light-border;
  border-width: 0px 0px 1px 0px;
  display: inline;
  background: none;
  align-items: top;

  .mat-step-header .mat-step-icon-selected {
    background-color: $button-bg-color;
  }
}

.c4p-question-set-input-scale {


  --mdc-slider-handle-color: var(--primary-color-600);
  --mdc-slider-focus-handle-color: var(--primary-color-600);
  --mdc-slider-hover-handle-color: var(--primary-color-600);
  --mdc-slider-active-track-color: var(--primary-color-600);
  --mdc-slider-label-container-color: var(--primary-color-600);
  --mat-slider-value-indicator-width: 15px !important;
  --mat-slider-value-indicator-height: 15px !important;
  --mat-slider-value-indicator-padding: 2px !important;
  --mat-slider-value-indicator-opacity: 0.5;
  --mat-slider-ripple-color: rgba(255,255,255,0.5) !important;
}

// ***************************************************************************
// comments
// ***************************************************************************
.c4p-form-comment-footer {
  padding: 5px 10px 0px 0px;
  justify-content: center !important;
  text-align: right;
  background-color: $card-shade-bg-color;
}
.c4p-form-comment-confirmation {
  justify-content: center !important;
  text-align: right;
  margin-top: 10px;
}

.c4p-form-comment-panel {
  background-color: var(--form-bg-color);
  color: var(--form-font-color);
  border-width: 1px;
  border-color: var(--form-bg-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: $tuula-font-family;
}
.c4p-form-comment-heading {
  background-color: $form-bg-color;
  color: $form-font-color;
  border-width: 1px;
  border-style: solid;
  border-color: $form-bg-color;
  border-radius: $border-radius;
  font-size: $font-medium;
  font-weight: 400;
  font-family: $tuula-font-family;
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
  text-align: center;
  padding: 20px 10px 10px 10px;
}
.c4p-form-comments-container-right {
  justify-content: left !important;
  text-align: left;
  border: solid;
  border-width: 1px 0px 1px 0px;
  border-color: $card-shade-line-color;
  background-color: transparent;
  padding: 16px 8px;
  word-break: break-all;
  width: calc(100% - 140px);
}
.c4p-form-comments-container {
  overflow-y: scroll;
  height: calc(80vh - 200px);
}
.c4p-form-comments {
  background-color: $form-bg-color;
  color: $form-font-color;
  border-width: 1px;
  border-style: solid;
  border-color: $form-bg-color;
  border-radius: $border-radius;
  font-size: $font-medium;
  font-weight: 400;
  font-family: $tuula-font-family;
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
  text-align: center;
  padding: 20px 10px 10px 10px;
}

.mat-divider {
  border-top-color: $button-bg-color;
}

// ***************************************************************************
// question set rendering
// ***************************************************************************
.c4p-form-questionset {
  height: 100%;
  border: solid;
  border-color: $light-border;
  border-width: 0px 0px 1px 0px;
  display: flex;
  background: none;
}

.c4p-form-questions {
  background-color: $form-bg-color;
  color: $form-font-color;
  border-width: 1px;
  border-style: solid;
  border-color: $form-bg-color;
  border-radius: $border-radius;
  font-size: $font-medium;
  font-weight: 400;
  font-family: $tuula-font-family;
  // margin: 5px 5px 5px 5px;
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
  // width: 100%;
}

.c4p-form-questions-tab {
  background-color: $form-bg-color;
  color: $form-font-color;
  border-width: 1px;
  // border-style: solid;
  border-color: $form-bg-color;
  border-radius: $border-radius;
  font-size: $font-medium;
  font-weight: 400;
  font-family: $tuula-font-family;
  margin: 5px 5px 5px 5px;
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
  width: 100%;
  overflow: hidden;
  min-height: 30vh;
}

.c4p-headerless-tabgroup {
  .mat-mdc-tab-header {
    display: none !important;
  }
}

.c4p-question-label {
  font-size: $font-normal;
  font-weight: 400;
  font-family: $tuula-font-family;
  margin-right: 8px;
  color: $form-title-font-color;
}

.c4p-form-row-questions-no-border {
  height: 100%;
  border-width: 0px 0px 0px 0px;
  align-items: center;
}

.c4p-form-row-questions {
  height: 100%;
  border: solid;
  border-color: var(--form-title-bg-color);
  border-width: 0px 0px 0px 10px;
  background-color: var(--form-bg-color);
  align-items: center;
  margin: 8px 4px;
  padding: 1px 8px;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 50%);
  border-radius: 2px;
}

.c4p-form-row-questions:first-child {
  word-break: break-all;
}

.c4p-form-questions-title {
  border: solid;
  border-color: $form-title-line-color;
  border-width: 0px 0px 1px 0px;
  height: 45px;
  position: relative;
  padding: 0px 20px 0px 5px;
  display: flex;
  flex-wrap: nowrap;
  background-color: $form-title-bg-color;
  color: $form-title-font-color;
  font-size: 13px;
  align-items: center;
}

.c4p-form-question-answers-container {
  overflow-y: scroll;
  max-height: 550px;
  height: auto;
  min-height: 30vh;
}

.c4p-form-questionset mat-table {
  max-height: 550px;
  overflow-y: auto;
}

.c4p-form-question-answers {
  margin: 20px 0px;
}

.c4p-question-set-input {
  min-height: 35px;
  vertical-align: middle;
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 0.9;
}

.c4p-question-set-input-small {
  vertical-align: middle;
  min-width: 100%;
  display: flex;
  align-items: center;
  border: solid;
  border-width: 0px 0px 1px 0px;
  border-color: var(--form-dynamic-bg-color);
  text-shadow: 0 0 black;
  padding: 4px 0px;
}

.c4p-question-set-input-big {
  min-height: 100px;
  vertical-align: middle;
  width: 100%;
  display: flex;
  align-items: center;
}

.c4p-hint-icons {
  font-size: 15px;
  // padding-top: 8px;
  cursor: pointer;
  opacity: 0.3;
  // color: $form-font-color;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-hint-icons:hover {
  opacity: 1;
  color: $sidebar-bg-color;
}

.c4p-mismatch-answer-row {
  align-items: baseline;
  padding: 0 16px;
  border-bottom: none;
}


// ***************************************************************************
// icons
// ***************************************************************************
.c4p-action-icons {
  font-size: $action-icon-size;
  // padding-top: 8px;
  cursor: pointer;
  opacity: 0.7;
  // color: $form-font-color;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.c4p-action-icons-external {
  font-size: $big-menu-icon-size;
  opacity: 0.9;
  flex-direction: row !important;
  align-items: baseline !important;
}
.c4p-action-icons:hover {
  opacity: 1;
  color: $sidebar-bg-color;
}

.c4p-action-badge {
  font-size: 20px;
  // padding-top: 8px;
  cursor: pointer;
  opacity: 0.7;
  // color: $form-font-color;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-action-badge:hover {
  opacity: 1;
  color: $sidebar-bg-color;
}

.mat-badge-content {
  font-size: 10px !important;
  width: auto !important;
  height: 22px !important;
  line-height: 22px !important;
  padding: 0 4px !important;
  white-space: nowrap;
}


.c4p-action-menu-icon {
  font-size: $action-icon-size;
  margin-top: 6px;
  margin-right: 0px !important;
  cursor: pointer;
  color: $sidebar-font-color !important;
}

.c4p-top-icons {
  font-size: $top-bar-icon-size;
  padding-top: 8px;
  cursor: pointer;
  color: $top-font-color;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-top-icons:hover {
  color: $sidebar-bg-color;
}
.c4p-back-icons {
  background-color: $button-bg-color;
  color: $button-font-color;
  border-style: solid;
  border-color: $button-line-color;
  border-width: 1px;
  border-radius: 3px;
  padding-top: 0px;
  margin-top: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: $top-bar-icon-size;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-right: 8px;
}

.c4p-back-icons:hover {
  background-color: $button-line-color;
}

.c4p-input-undo-icon {
  font-size: 13px !important;
  position: absolute;
  top: -10px;
  right: 2px;
  z-index: 1;
  opacity: 0.2;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-input-undo-icon:hover {
  opacity: 1;
  color: $sidebar-bg-color;
}

// ***************************************************************************
// override angular material for select
// ***************************************************************************
.mat-mdc-select-panel {
  background-color: $form-bg-color !important;
  color: $form-font-color;
  border-color: $dark-border;
  border-radius: $border-radius;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-select-value {
  color: $form-font-color;
}

.mat-mdc-select {
  font-size: $font-normal !important;
  font-family: $tuula-font-family !important;
  color: $form-font-color;
}
.mat-mdc-option {
  font-size: $font-normal !important;
  color: $form-font-color;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  line-height: 16px !important;
  min-height: 32px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: $form-font-color !important;
  font-family: $tuula-font-family;
}

.mat-mdc-select-panel
  .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
  background-color: $sidebar-bg-color !important;
  color: $sidebar-font-color !important;
}

.mat-mdc-option.mdc-list-item{
  justify-content: center;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background-color: $sidebar-bg-color !important;
  color: $sidebar-font-color !important;
  .mat-pseudo-checkbox {
    display: none !important;
  }
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  )
  .mdc-list-item__primary-text {
  color: $sidebar-font-color !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: $form-font-color !important;
}

.mat-mdc-option.mdc-list-item:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: $form-font-color !important;
}

.mat-select-search-input {
  background-color: #fff !important;
}

.mat-select-search-inner .mat-divider {
  border-top-color: $form-line-color;
}

// ***************************************************************************
// override angular material for slide toggle
// ***************************************************************************

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-handle-color:  var(--white) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-color-600) !important;

  --mdc-switch-selected-handle-color: var(--white) !important;
  --mdc-switch-selected-focus-handle-color: var(--white) !important;
  --mdc-switch-selected-hover-handle-color: var(--white) !important;
  --mdc-switch-selected-pressed-handle-color: var(--white) !important;

  --mdc-switch-unselected-handle-color: var(--white) !important;
  --mdc-switch-unselected-focus-handle-color: var(--white) !important;
  --mdc-switch-unselected-hover-handle-color: var(--white) !important;
  --mdc-switch-unselected-pressed-handle-color: var(--white) !important;


  --mdc-switch-selected-focus-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-hover-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-pressed-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-track-color: var(--primary-color-600) !important;

  --mdc-switch-unselected-focus-track-color: var(--gray-color-100) !important;
  --mdc-switch-unselected-hover-track-color: var(--gray-color-100)!important;
  --mdc-switch-unselected-pressed-track-color: var(--gray-color-100) !important;
  --mdc-switch-unselected-track-color: var(--gray-color-100) !important;

  --mdc-switch-selected-icon-color:   var(--white) !important;/*tick color*/
  --mdc-switch-unselected-icon-color:  var(--gray-color-100) !important; /*minus color*/

  --mat-switch-track-outline-color: var(--gray-color-300) !important;
  --mat-switch-disabled-unselected-track-outline-color: var(--gray-color-200) !important;
  --mat-switch-label-text-color: var(--gray-color-900) !important;


  --mdc-switch-track-width: 40px;
  --mdc-switch-track-height: 24px;
  --mdc-switch-track-shape: 9999px;

  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;

  --mdc-switch-state-layer-size: 24px;
}

// ***************************************************************************
// override angular material for accordion
// ***************************************************************************
mat-expansion-panel {
  margin: 0 !important;
  padding: 0 !important;
  font-family: $tuula-font-family;
}

.mat-expansion-panel-body {
  padding: 0 0px 0px !important;
  font-family: $tuula-font-family;
  background: linear-gradient(
    to right,
    $sidebar-line-color,
    $sidebar-bg-color
  ) !important;
  // background: -webkit-linear-gradient(to right, $sidebar-line-color, rgba(255,255,255,0)) !important;
  // background: -moz-linear-gradient(to right, $sidebar-line-color, transparent) !important;
  // background: -o-linear-gradient(to right, $sidebar-line-color, transparent) !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0px !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 24px !important;
}

.mat-expansion-panel-header.mat-expanded {
  background: linear-gradient(
    to right,
    $sidebar-line-color,
    $sidebar-bg-color
  ) !important;
  // background: -webkit-linear-gradient(to right, $sidebar-line-color, rgba(255,255,255,0)) !important;
  // background: -moz-linear-gradient(to right, $sidebar-line-color, transparent) !important;
  // background: -o-linear-gradient(to right, $sidebar-line-color, transparent) !important;
}

// ***************************************************************************
// override angular material for radio button
// ***************************************************************************

.mat-mdc-radio-button {
  .mdc-label {
    font-family: $tuula-font-family !important;
    font-size: $font-normal !important;
    color: $form-font-color !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-outer-circle {
  height: 14px !important;
  width: 14px !important;
  top: 3px !important;
  left: 3px !important;
  border-width: 1px !important;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--sidebar-bg-color);
  --mdc-radio-selected-hover-icon-color: var(--sidebar-bg-color);
  --mdc-radio-selected-icon-color: var(--sidebar-bg-color);
  --mdc-radio-selected-pressed-icon-color: var(--sidebar-bg-color);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--sidebar-bg-color);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-container {
  width: 15px !important;
}

// ***************************************************************************
// override angular material for input
// ***************************************************************************

.mat-mdc-form-field {
  width: 100% !important;
}

.mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
  min-height: 46px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mdc-line-ripple {
  // height: 0px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
  padding-right: 0;
  padding-left: 0;
}

.mat-mdc-floating-label {
  line-height: 1.15rem !important;
}

.mat-mdc-input-element {
  font-family: $tuula-font-family !important;
  font-size: $font-normal !important;
  color: $form-font-color !important;
}

.mat-mdc-input-element:disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.8) !important;
  -webkit-opacity: 0.8 !important;
  opacity: 0.8 !important;
}

input.mat-mdc-input-element {
  color: $form-font-color;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  width: 100%;
  max-width: 100%;
  font-family: $tuula-font-family;
  font-size: $font-normal;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
input::placeholder,
.mat-mdc-form-field-text-suffix,
.mat-mdc-form-field-text-prefix,
.mat-mdc-floating-label {
  color: $form-font-color !important;
  font-size: $font-normal !important;
  opacity: 0.6;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mdc-line-ripple {
  background-color: $form-line-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused .mdc-line-ripple {
  background-color: $form-line-color;
}
.mdc-text-field--filled .mdc-line-ripple::before {
  border-bottom-width: 1px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: $form-line-color !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-line-ripple::after {
  border-bottom-color: $warning-border !important;
}

.mdc-line-ripple--activate {
  background-color: $form-line-color;
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before,
.mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::after {
  border: 1px solid;
  border-style: dotted;
  border-color: $form-line-color !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-mdc-floating-label {
  top: 20px;
  font-size: $font-normal !important;
}

.mat-mdc-form-field-error {
  color: $warning-border !important;
}
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-error-custom {
  color: $warning-border !important;
  font-size: 75%;
  position: absolute;
  margin-top: 45px;
}

.mat-mdc-form-field.mat-form-field-invalid .mat-mdc-floating-label.mat-accent,
.mat-form-field.mat-form-field-invalid
  .mat-mdc-floating-label
  .mat-form-field-required-marker {
  color: unset;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-focused .mat-form-field-required-marker {
  color: unset;
}

// ***************************************************************************
// override angular material for calendar
// ***************************************************************************
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-datepicker-content{
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.mat-datepicker-toggle,
.mat-mdc-select-arrow,
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: var(--gray-color-700);
  opacity: 0.65;
}

mat-datepicker-toggle .mat-mdc-icon-button svg {
  width: 12px !important;
  height: 12px !important;
  color: #8c8d8d;
  position: absolute !important;
  bottom: 20% !important;
  right: 20% !important;
}

.mat-datepicker-content .mat-calendar,
.mat-datepicker-content .time-container {
  font-size: $font-normal !important;
  font-family: $tuula-font-family !important;
  color: var(--gray-color-700);
  background-color: $form-bg-color;
  border-color: $form-line-color;
}

.mat-datepicker-actions {
  background-color: $form-bg-color;
}

.mat-datepicker-actions {
	.mat-mdc-outlined-button:not(:disabled) {
		color:var(--gray-color-700);
	}
}

.mat-calendar-body {
  font-size: $font-normal !important;
}
.mat-calendar-body-cell-content {
  color: var(--gray-color-700) !important;
  border-color: transparent !important;
}

.mat-calendar-body-selected {
  background-color: $sidebar-bg-color !important;
  color: $sidebar-font-color !important;
  border-color: $sidebar-line-color !important;
}


.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: rgba(33, 150, 243, 0.3) !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: rgba(0, 0, 0, 0.38) !important;
}

// ***************************************************************************
// override angular material for check box
// ***************************************************************************
.mat-mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: $checkbox-disabled-checked-checkmark-color !important;
  --mdc-checkbox-selected-icon-color: $checkbox-checked-bg-color !important;

  border: none;
  padding-bottom: 6px;
  border-width: 0px 0px 1px 0px;
  opacity: 1;
  .mdc-label {
    font-family: $tuula-font-family;
    font-size: $font-normal;
    color: $form-font-color;
    white-space: break-spaces;
    word-break: break-word;
  }

}

.mat-pseudo-checkbox,
.mdc-checkbox__background,
.mdc-checkbox {
  border-width: 1px !important;
  border-radius: 4px !important;
  border-color: $checkbox-unchecked-border-color !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background  {
  background-color: $checkbox-checked-bg-color !important;
  border-color: $checkbox-checked-border-color !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background{
  background-color: $checkbox-disabled-checked-bg-color !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  background-color: $checkbox-disabled-unchecked-bg-color !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: $checkbox-checked-checkmark-color !important;
}

.mdc-checkbox .mdc-checkbox__native-control:disabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: $checkbox-disabled-checked-checkmark-color !important;
}

// ***************************************************************************
// override angular material for text area
// ***************************************************************************
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
textarea.mat-mdc-input-element {
  padding: 0;
  margin: 0;
}

// ***************************************************************************
// override angular material for button toggle
// ***************************************************************************
.mat-button-toggle-appearance-standard {

  .mat-pseudo-checkbox {
    width: auto !important;
    height: auto !important;
    border: none !important;
  }
  .mat-button-toggle-label-content, .mat-button-toggle-focus-overlay {
    border: none !important;
    margin: 0 !important;
  }
}

// ***************************************************************************
// override angular material for tabs
// ***************************************************************************
.mat-mdc-tab {
  border: solid !important;
  border-width: 0px 0px 2px 0px !important;
  border-color: $button-bg-color !important;
  opacity: 0.6 !important;
  margin: 0px 1px 0px 1px !important;
}

.mdc-tab--active {
  opacity: 1 !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: $button-bg-color !important;
}
.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 4px;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $button-bg-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-link {
  font-family: $tuula-font-family;
  font-size: 13px;
  font-weight: 400;
  color: $top-font-color;
  min-width: 0px !important;
  height: 40px !important;
  padding: 0px 8px 0px 8px !important;
  margin: 0px 1px 0px 1px !important;
  border: solid !important;
  border-width: 0px 0px 4px 0px !important;
  border-color: $button-bg-color !important;
  opacity: 0.6 !important;
  // height: 48px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mdc-tab--active {
  opacity: 1 !important;
}

.mdc-tab-indicator__content--underline {
  border: none !important;
}

.mat-mdc-tab-labels {
  display: flex;
  width: 25%;
  flex: 1 0 auto;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-labels:last-child.mat-tab-label {
  min-width: 0;
  padding: 0 12px;
  color: unset;
  opacity: unset;
  right: 0px;
}

.mat-mdc-tab-header {
  // background-color: $top-bg-color;
  border-bottom-color: $master-line-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  height: auto !important;
  overflow: hidden !important;
  // padding: 2px;
  // background-color: $form-bg-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-group[mat-stretch-tabs] > .mat-mdc-tab-header .mat-tab-label {
  flex-basis: auto !important;
  flex-grow: 0 !important;
}

// ***************************************************************************
// override angular material for dropdown
// ***************************************************************************
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-select-disabled .mat-mdc-select-value {
  color: $form-font-color;
}

// ***************************************************************************
// override angular material for spinner
// ***************************************************************************
.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: $sidebar-bg-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-progress-bar-background {
  fill: $sidebar-line-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mdc-linear-progress__buffer {
  background-color: $sidebar-bg-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-progress-bar-fill::after {
  background-color: $master-line-color;
}

.c4p-progress-bar {
  background-color: $top-bg-color;
  height: 1px !important;
}

// ***************************************************************************
// table
// ***************************************************************************
.c4p-column-filter {
  height: 20px;
  border: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 0px 0px 0px 4px;
  background-color: rgba(0, 0, 0, 0.04);
  border-color: rgba(0, 0, 0, 0.05);
  margin: 5px 0px;
}

.mat-mdc-icon-button:disabled {
  opacity: var(--mdc-icon-button-disabled-icon-opacity) !important;
}

.c4p-table-view-wrap {
  background-color: $form-title-bg-color;
  color: $form-title-font-color !important;
  border-width: 0px;
  border-style: solid;
  border-color: $form-title-line-color;
  border-radius: $border-radius;
  font-size: $font-medium;
  font-weight: 400;
  font-family: $tuula-font-family;
  min-width: 315px;
  .c4p-table-title-bar {
    display: flex;
    flex: wrap;
    flex-direction: row;
    flex-basis: auto;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-color-200);
    background-color: $white;
    height: 45px;
    // min-width: 350px;
  }
  // override angular materials
  .mat-mdc-table {
    overflow-x: auto;
    font-family: $tuula-font-family !important;
  }
  .mat-mdc-header-cell {
    background-color: $table-header-bg-color;
    color: $table-header-font-color;
    font-size: $font-normal;
    font-weight: 500;
  }
  .mat-mdc-cell {
    color: $form-font-color;
    font-family: $tuula-font-family;
    font-size: $font-normal;
    font-weight: 400;
  }
  .mat-sort-header {
    color: $table-header-font-color;
    padding-top: 5px;
  }
  .mat-mdc-row,
  mat-header-row,
  mat-footer-row {
    border-color: $form-line-color !important;
  }
  .mat-mdc-header-row {
    min-height: 35px;
    border-color: $form-line-color;
    background-color: var(--table-header-bg-color);
  }
  .mat-mdc-row {
    background-color: $form-bg-color !important;
    border-color: $light-border !important;
    color: $form-font-color !important;
  }
  .mat-mdc-row:hover {
    background-color: $light-border !important;
  }
  .mat-mdc-row.highlighted {
    background-color: $form-title-bg-color !important;
    color: $sidebar-font-color !important;
    border-left-width: 5px !important;
    border-left-color: $sidebar-bg-color !important;
    border-bottom-color: $form-title-line-color !important;
  }
  .mat-mdc-row.highlighted-non-selection {
    background-color: $form-title-bg-color !important;
    color: $sidebar-font-color !important;
    border-bottom-color: $form-title-line-color !important;
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell,
  .mdc-data-table__footer-cell {
    padding: 0;
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell,
  .mdc-data-table__footer-cell {
    box-sizing: content-box;
  }

  .mat-mdc-cell:first-of-type,
  .mat-mdc-header-cell:first-of-type,
  .mat-mdc-footer-cell:first-of-type {
    padding-left: 24px;
  }

  .mat-mdc-cell:last-of-type,
  .mat-mdc-header-cell:last-of-type,
  .last-mdc-footer-cell:last-of-type {
    padding-right: 24px;
  }

  .mat-mdc-paginator {
    color: $form-title-font-color;
    background-color: transparent;
    font-family: $tuula-font-family;
  }
  .mat-mdc-paginator-icon {
    width: 20px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
  .mat-mdc-paginator-page-size-select {
    color: $form-title-font-color !important;
    width: 55px !important;
  }

  .mat-mdc-paginator .mat-mdc-form-field-infix {
    min-height: 40px !important;
  }

  .mat-mdc-paginator,
  .mat-mdc-paginator-page-size .mat-mdc-select-trigger,
  .mat-mdc-paginator-page-size .mat-mdc-select-value,
  .mat-mdc-paginator-page-size .mat-mdc-select-arrow {
    color: $form-title-font-color !important;
    font-size: $font-small;
  }
  .mat-mdc-paginator-range-label {
    margin: 0px 10px 0px 10px;
  }
  .mat-mdc-button.mat-primary[disabled],
  .mat-button.mat-accent[disabled],
  .mat-button.mat-warn[disabled],
  .mat-button[disabled][disabled],
  .mat-mdc-icon-button.mat-primary[disabled],
  .mat-icon-button.mat-accent[disabled],
  .mat-icon-button.mat-warn[disabled],
  .mat-icon-button[disabled][disabled],
  .mat-mdc-outlined-button.mat-primary[disabled],
  .mat-stroked-button.mat-accent[disabled],
  .mat-stroked-button.mat-warn[disabled],
  .mat-stroked-button[disabled][disabled] {
    color: $form-title-font-color;
    font-size: $font-normal;
    opacity: 0.6;
  }
  .mat-mdc-paginator-outer-container {
    height: auto;
    align-items: center;
  }
  .mat-sort-header-arrow {
    color: $table-header-font-color;
  }
  .mat-sort-header-button {
    color: $table-header-font-color;
    font-family: $tuula-font-family;
  }
  .mat-column-menu_action {
    flex: 0 0 30px;
  }
  //   .mat-sort-header-content {
  //     width: 90%;
  //   }
  //   .mat-sort-header-container {
  //     width: 90%;
  //   }
  table.mat-mdc-table {
    display: table;
  }
}

// Added due to overlapping of paginator with the scroll bar between mat-table and mat-paginator

.mat-mdc-paginator-container {
  min-height: auto;
  height: auto;
}

.mat-mdc-paginator-range-actions {
  height: inherit;
}

.mat-mdc-paginator-page-size {
  align-items: center;
  height: inherit;
}

// ***************************************************************************
// user info
// ***************************************************************************
.cp4-collaboration-avatar {
  background-color: white;
  border: solid;
  border-radius: 50%;
  border-width: 1px;
  z-index: 0;
  margin-left: -10px;
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
}

.cp4-collaboration-avatar:hover {
  z-index: 10;
  cursor: pointer;
}

.cp4-collaboration-avatar-active {
  // border-color: green;
  border-color: $top-bg-color;
}

.cp4-collaboration-avatar-inactive {
  border-color: $top-bg-color;
}

// ***************************************************************************
// menu
// ***************************************************************************
.c4p-menu {
  background-color: $sidebar-bg-color !important;
  border: solid;
  border-radius: $border-radius !important;
  border-width: 1px;
  border-color: $dark-border;
}

.c4p-menu-item {
  border-color: $sidebar-line-color !important;
  border: solid !important;
  border-width: 0px 0px 1px 0px !important;
}

.c4p-menu-item[disabled='true'] > span.c4p-menu-title,
div.disabled-inline-action {
  opacity: 0.5;
  pointer-events: none;
}

.c4p-menu-title {
  font-size: $font-normal;
  font-weight: 400;
  color: $sidebar-font-color;
}

// override angular material
.mat-mdc-menu-item {
  border-color: $sidebar-line-color !important;
  color: $sidebar-font-color;
  font-family: $tuula-font-family;
  padding: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-bottom: solid 1px var(--primary-color-400) !important;

}

.mat-mdc-menu-item .mat-icon-no-color {
  color: $sidebar-font-color;
}

.mat-icon {
  outline: none;
  height: 24px !important;
  width: 24px !important;
}

.mat-mdc-menu-content {
  padding: 0px !important;
}

.mat-mdc-menu-panel {
  min-height: 0px !important;
}

.lang-menu {
  margin-top: 5px !important;
}
// ***************************************************************************
// status icon
// ***************************************************************************
.c4p-status-icon {
  font-size: 12px;
  font-weight: 500;
  min-width: 60px;
  text-align: center;
  padding: 4px 8px;
  mix-blend-mode: multiply;
  border-radius: 16px;
  background-color: $button-bg-color;
  color: $white;
}

.c4p-status-icon-final, .c4p-status-icon-done,
 .c4p-status-icon-confirmed, .c4p-status-icon-processed, .c4p-status-icon-signed,
 .c4p-status-icon-open, .c4p-status-icon-completed, .c4p-status-icon-invoiced,
 .c4p-status-icon-concept_invoice, .c4p-status-icon-approved,
.c4p-status-icon-unchanged, .c4p-status-icon-ready, .c4p-status-icon-accepted {
  background-color: $success-bg-color;
  color: $success-color;
  border:1px solid $success-border-color;
}

.c4p-status-icon-active, .c4p-status-icon-proposed, .c4p-status-icon-partially_accepted,
.c4p-status-icon-assigned, .c4p-status-icon-archived{
  background-color: $info-bg-color;
  color: $info-color;
  border:1px solid $info-border-color;
}

.c4p-status-icon-declined, .c4p-status-icon-processing {
  background-color: $pink-bg-color;
  color: $pink-color;
  border:1px solid $pink-border-color;
}

.c4p-status-icon-inactive {
  background-color: $inactive-bg-color;
  color: $inactive-color;
  border:1px solid $inactive-border-color;
}

.c4p-status-icon-draft, .c4p-status-icon-pending, .c4p-status-icon-zpm-draft,
.c4p-status-icon-deleted, .c4p-status-icon-rejected{
  background-color: $warning-bg-color;
  color: $warning-color;
  border:1px solid $warning-border-color;
}

.c4p-status-icon-closed, .c4p-status-icon-dropped_out, .c4p-status-icon-failed,
.c4p-status-icon-locked, .c4p-status-icon-invalid, .c4p-status-icon-titlechanged,
.c4p-status-icon-typechanged, .c4p-status-icon-error{
  background-color: $error-bg-color;
  color: $error-color;
  border:1px solid $error-border-color;
}

.mat-badge-warn {
  --mat-badge-background-color: var(--error-color-100);
  --mat-badge-text-color: var(--error-color-700);;
}

.c4p-status-icon-unknown, .c4p-status-icon-cancelled, .c4p-status-icon-submitted,
.c4p-status-icon-correction {
  background-color: $unknown-bg-color;
  color: $unknown-color;
  border:1px solid $unknown-border-color;
}

.c4p-progress-back {
  background: solid;
  background-color: $error-bg-color;
  margin-right: 2px;
  border:1px solid $error-border-color;
  color: $error-color;
  border-radius: 2px;
  text-align: center !important;
  vertical-align: middle;
  width: 75px;
  height: 22px;
  position: relative;
}

.c4p-progress-front {
  background: solid;
  background-color: $success-border-color;  // $success-border-color
  border:1px solid $success-border-color;  //var(--success-color-500)
  color: $success-color;
  border-radius: 2px;
  height: 22px;
  position: absolute;
  top: -1px;
  left: -1px;
}

// ***************************************************************************
// boolean icon
// ***************************************************************************
.c4p-indicator-icon {
  padding-top: 8px;
  font-size: $action-icon-size;
}

.c4p-indicator-icon-true {
  color: #0eaf26;
}

.c4p-indicator-icon-false {
  color: #db820f;
}

.c4p-unread-application-indicator-icon {
  color: #df7513;
}

// ***************************************************************************
// tooltip
// ***************************************************************************
.mat-mdc-tooltip {
  color: white;
  background-color: #5c5c5c;
  font-size: $font-x-small;
  font-family: $tuula-font-family;
}

// ***************************************************************************
// search field
// ***************************************************************************
.c4p-search {
  display: inline-flex;
  text-align: left;
  max-width: 150px;
  font-size: $font-normal;
  background-color: $form-bg-color;
  color: $master-font-color;
  border-width: 1px;
  border-style: solid;
  border-color: $form-title-line-color;
  border-radius: $border-radius;
  margin-top: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
  width: 130px;
}

.c4p-search-icon {
  font-size: 18px;
  margin-top: 6px;
  margin-left: 4px;
  cursor: pointer;
  color: $form-font-color;
}

// ***************************************************************************
// grid
// ***************************************************************************
.c4p-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  // justify-content: space-evenly;
  flex-basis: auto;
  background-color: $form-bg-color;
}

.c4p-grid-item {
  min-width: 280px;
  margin: 8px 4px 8px 0px;
  border: solid;
  border-width: 1px 1px 1px 1px !important;
  border-color: $form-bg-color;
}

.c4p-grid-note {
  width: 265px;
  margin: 8px 4px 8px 0px;
  border: solid;
  border-width: 1px 1px 1px 1px !important;
  border-color: $form-bg-color;
}

.c4p-panel-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  flex-basis: auto;
  background-color: $form-bg-color;
}

.c4p-panel-grid-item {
  min-width: 320px;
  flex-grow: 1;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
}

// ***************************************************************************
// card view
// ***************************************************************************
.c4p-card-view {
  border-radius: $border-radius !important;
  border-width: 1px 1px 1px 1px !important;
  border: solid;
  border-color: $card-line-color;
  background-color: $card-shade-bg-color2;

  font-family: $tuula-font-family;
  font-size: $font-normal;
  margin: 4px;
  padding: 0px !important;
  box-shadow: 0px 0px 0px rgba(60, 60, 60, 0.4) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-card-view:hover {
  box-shadow: 0px 0px 6px rgba(60, 60, 60, 0.3) !important;
}

.c4p-card-view-left {
  justify-content: center !important;
  text-align: center;
  border: solid;
  border-width: 1px 1px 1px 0px;
  border-color: $card-shade-line-color;
  background-color: $card-bg-color;
  width: 110px;
  padding: 8px;
}

.c4p-card-view-title {
  color: $form-title-font-color;
  height: 8px;
}

.c4p-card-view-right {
  justify-content: left !important;
  text-align: left;
  border: solid;
  border-width: 1px 0px 1px 0px;
  border-color: $card-shade-line-color;
  background-color: $card-shade-bg-color;
  width: 160px;
  padding: 16px 8px;
}

.c4p-card-view-label {
  margin: 8px 0px 4px 0px;
}

.c4p-card-view-value {
  margin: 4px 0px 12px 0px;
}

.c4p-card-view-title {
  margin: 0px 5px;
  justify-content: space-between;
}

.notes-title {
  font-weight: bolder !important;
  height: 15px;
}

.c4p-card-view-value-list {
  margin: 6px 0px 12px 0px;
}

.c4p-card-view-list-item {
  padding: 2px 1px 2px 1px;
  margin: 0px 2px;
  border-radius: $border-radius;
  border-width: 1px;
  border-color: $card-shade-line-color;
  color: $form-font-color;
  text-align: center;
  font-size: $font-normal;
  height: auto;
}

.c4p-card-view-list-item:hover {
  background-color: $sidebar-bg-color;
  color: $sidebar-font-color;
  cursor: pointer;
}

.c4p-card-view-list-item-popup {
  padding: 2px 1px 2px 1px;
  margin: 0px 2px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: $border-radius;
  border: solid;
  border-width: 1px;
  border-color: $card-shade-line-color;
  color: $form-font-color;
  background-color: $form-bg-color;
  text-align: center;
  font-size: $font-normal;
  height: 13px;
}

.c4p-card-view-list-item-popup:hover {
  background-color: $sidebar-bg-color;
  color: $sidebar-font-color;
  cursor: pointer;
}

.c4p-card-view-header {
  padding: 15px;
  justify-content: center !important;
  text-align: center;
}

.c4p-note-view-header {
  padding: 10px 0 10px 5px;
  align-items: center !important;
}

.c4p-card-view-notification {
  text-align: right;
}

.c4p-card-view-content {
  border: solid;
  border-width: 1px 0px 1px 0px;
  border-color: $card-shade-line-color;
  background-color: $card-shade-bg-color;
  color: $card-shade-font-color;
  padding: 5px;
  height: 152px;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.c4p-card-view-footer {
  padding: 10px 0px 0px 0px;
  justify-content: center !important;
  text-align: center;
  background-color: $card-shade-bg-color;
}

.c4p-card-notes-footer {
  padding: 5px 10px 0px 5px;
  background-color: $card-shade-bg-color;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.c4p-card-view-img-circle {
  height: 96px;
  border-radius: 50%;
}

.c4p-avatar-small {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: white;
}

.c4p-avatar {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: white;
}

.c4p-avatar-input {
  display: flex;
}

.c4p-avatar-input-edit {
  display: flex;
}

.c4p-avatar-input-edit:hover {
  cursor: pointer;
}

.c4p-collaboration-detail-card {
  border-radius: $border-radius !important;
  border-width: 1px;
  border-color: $card-shade-line-color !important;
  background-color: white !important;
  padding: 10px 15px;
}

// ***************************************************************************
// fonts
// ***************************************************************************
.c4p-dimmed {
  opacity: 0.6;
}

.c4p-dimmed .avatar-content {
  opacity: 0.5 !important;
}

.c4p-font-bold {
  font-weight: 600 !important;
}

.c4p-font-xlarge {
  font-family: $tuula-font-family;
  font-size: $font-xlarge;
  font-weight: 400;
}

.c4p-font-large {
  font-family: $tuula-font-family;
  font-size: $font-large;
  font-weight: 400;
}

.c4p-font-medium {
  font-family: $tuula-font-family;
  font-size: $font-medium;
  font-weight: 400;
}

.c4p-font-normal {
  font-family: $tuula-font-family;
  font-size: $font-normal;
  font-weight: 400;
}

.c4p-font-small {
  font-family: $tuula-font-family;
  font-size: $font-small;
  font-weight: 400;
}


.mat-mdc-list-base {
  padding-top: 8px;
  display: block;
  -webkit-tap-highlight-color: transparent;
  word-break: break-word;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list-option:not(.mat-mdc-list-item-disabled) {
  cursor: pointer;
  outline: none;
  margin: 4px 6px 6px 5px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 40%);
  border-radius: 4px;
  width: 290px;
  padding: 5px 0px;
  background-color: white;
}
.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-icon,
.mat-mdc-list-base .mat-mdc-list-option .mat-mdc-list-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 24px;
  box-sizing: content-box;
  border-radius: 50%;
  opacity: 0.5;
  padding: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list-base .mat-mdc-list-option > * {
  margin: 1px;
  padding: 0;
  font-weight: normal;
  font-size: $font-small !important;
  opacity: 1;
  color: $form-font-color !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list-base .mat-mdc-list-option .mat-line:nth-child(n + 2) {
  font-size: $font-normal !important;
  color: $form-title-font-color !important;
  font-weight: 400 !important;
}



// ***************************************************************************
// dialog
// ***************************************************************************
.mat-mdc-dialog-container {
  color: $popup-notify-font-color;
  padding: 0px !important;
  margin: 0px !important;
  background-color: $master-bg-color !important;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: $dark-border;
  border-radius: $border-radius;
  font-size: $font-large;
  font-weight: 400;
  font-family: $tuula-font-family;
  // box-shadow: 0px 0px 10px rgba(0,0,0,0.5), 0px 0px 1px black;
  max-width: 100%;
  // min-width: 340px;
  overflow: visible !important;
}

.invoice-credit-replace-dialog-borderless{
  .mat-mdc-dialog-container{
    border-style: none !important;
  }
}

.c4p-popup {
  width: 100%;
}

.c4p-popup-title {
  background-color: $popup-notify-bg-color !important;
  color: $popup-notify-font-color;
  font-family: $tuula-font-family;
  font-size: $font-large;
  height: 35px;
  position: relative;
  padding-top: 20px;
  padding-left: 10px;
}

.c4p-form-input-listbox-bold {
  background-color: $master-bg-color !important;
}

.c4p-popup-title-small {
  background-color: $popup-notify-bg-color !important;
  color: $popup-notify-font-color;
  font-family: $tuula-font-family;
  font-size: $font-medium;
  height: 25px;
  position: relative;
  padding-top: 10px;
  padding-left: 10px;
}

.c4p-popup-content-top {
  display: flex;
  border-radius: 0px;
  border-width: 1px 0px 0px 0px !important;
  border: solid;
  box-shadow: none;
  border-color: $form-title-line-color;
  background-color: $form-bg-color !important;
  color: $form-font-color !important;
  font-family: $tuula-font-family;
  font-size: $font-normal;
  padding: 10px 30px 10px 20px;
}

.c4p-popup-content-down {
  display: flex;
  border-radius: 0px;
  border-width: 1px 0px 0px 0px !important;
  border: solid;
  box-shadow: none;
  border-color: $form-line-color;
  background-color: $form-shade-bg-color !important;
  color: $form-font-color !important;
  font-family: $tuula-font-family;
  font-size: $font-normal;
  padding: 10px 20px 10px 20px;
}

.c4p-popup-content-left-part {
  display: inline-block;
  width: 25px;
  vertical-align: top;
  padding-top: 3px;
}

.c4p-popup-content-right-part {
  display: inline-block;
  width: calc(100% - 25px);
  vertical-align: middle;
}

.maxh .c4p-popup-form {
  max-height: 92vh;
  min-width: 55vw;
}

// ***************************************************************************
// buttons
// ***************************************************************************
.c4p-hidden-button {
  text-align: center;
  text-shadow: 0px 0px 3px black;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $button-bg-color;;
  color: white;
  cursor: pointer;
  font-size: $font-normal;
  transition: width 0.5s;
}

.c4p-hidden-button:empty {
  width: 0%;
}

.c4p-button-ok {
  // display:flex;
  text-align: center;
  text-shadow: 0px 0px 3px black;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color:  $button-bg-color;
  color: white;
  cursor: pointer;
  font-size: $font-normal;
  transition: width 0.5s;
  margin: 0px 2px 2px 2px;
  border-radius: $border-radius;
}

.c4p-button-ok:hover {
  background-color: $button-bg-hover-color;
}

.c4p-button-ok:empty {
  width: 0%;
}

.sticky-btm-buttons {
  display: flex;
  position: sticky;
  bottom: 0px;
}

.c4p-button-cancel {
  // display:flex;
  text-align: center;
  width: 100%;
  background-color: $white;
  padding-top: 8px;
  padding-bottom: 8px;
  color: $button-cancel-color;
  cursor: pointer;
  font-size: $font-normal;
  transition: width 1s;
  margin: 0px 2px 2px 2px;
  border-radius: $border-radius;
  border: 1px solid $button-cancel-border-color;

}

.c4p-button-cancel:hover {
  background-color: $button-cancel-bg-hover-color;
}

.c4p-button-delete {
  // display:flex;
  text-align: center;
  text-shadow: 0px 0px 3px black;
  width: 100%;
  background-color: #d3c314;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  cursor: pointer;
  font-size: $font-normal;
  transition: width 1s;
  margin: 0px 2px 2px 2px;
  border-radius: $border-radius;
}

.c4p-button-delete:hover {
  background-color: $button-bg-hover-color;
}

.c4p-button {
  text-align: center;
  // text-shadow: 0px 0px 2px rgba(0,0,0,1);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0px 8px 0px 8px;
  background-color: $button-bg-color;
  border: solid;
  border-width: 1px;
  border-radius: $border-radius;
  color: $button-font-color;
  cursor: pointer;
  font-size: $font-normal;
}

.c4p-button:hover {
  background-color: $button-bg-hover-color;
}

.c4p-button-comment {
  margin: 5px 3px 5px 3px !important;
  font-size: $font-normal;
}

.c4p-button:hover:enabled {
  background-color: $button-line-color;
}

.c4p-button-icon {
  color: #1db0e8;
}

.c4p-button[disabled] {
  opacity: 0.5;
  cursor: auto;
}

// ***************************************************************************
// tabs
// ***************************************************************************
.c4p-tab {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px 1px 0px 0px;
  font-size: $font-medium;
  font-weight: 400;
  font-family: $tuula-font-family;
  background-color: $top-bg-color;
  border: solid;
  border-color: $button-bg-color;
  border-width: 0px 0px 4px 0px;
  border-radius: 0px;
  color: $button-bg-color;
  cursor: pointer;
  font-size: $font-medium;
}

.c4p-dimmed-tab {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px 1px 0px 0px;
  font-size: $font-medium;
  font-weight: 400;
  font-family: $tuula-font-family;
  border: solid;
  border-width: 0px 0px 4px 0px;
  border-radius: 0px;
  border-color: $button-bg-color;
  outline: none;
  color: $top-font-color;
  cursor: pointer;
  font-size: $font-medium;
  opacity: 0.6;
}

.c4p-active-tab {
  opacity: 1;
  // background-color: $top-bg-color;
  // border-color: $button-bg-color;
}

.c4p-tab:hover,
.c4p-dimmed-tab:hover {
  opacity: 1;
}

.c4p-spinner-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.c4p-progress-center {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
}

.c4p-spinner-overlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.c4p-breadcrumb {
  cursor: pointer;
  outline: none;
  font-size: 20px;
  opacity: 0.7;
}

.c4p-breadcrumb-active {
  // color: var(--button-bg-color);
  font-weight: 400;
  outline: none;
  opacity: 1 !important;
}

// ***************************************************************************
// drag*drop
// ***************************************************************************
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-mdc-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
// ***************************************************************************
// questionset edit - roles component
// ***************************************************************************
.roles-table {
  height: 100%;
  max-height: 250px !important;
  width: 100%;
}

.checkbox-cell {
  justify-content: center;
}

.mat-mdc-cell {
  color: $form-font-color;
  font-family: $tuula-font-family;
  font-size: $font-normal;
  font-weight: 400;
}

.permissions-panel {
  .mat-expansion-panel-header {
    font-size: $font-normal !important;
  }

  .mat-expansion-panel-header.mat-expanded {
    font-family: $tuula-font-family;
    font-size: $font-normal !important;
    font-weight: 400;
    background: $table-header-bg-color !important;
  }
}
// C4P-FLEX
.c4p-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.c4p-flex-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 60;
  flex-grow: 1;
}

.c4p-change-indicator {
  font-size: 16px;
  opacity: 0.7;
  margin-left: auto;
  color: var(--button-line-color);
  animation: pulse-icon 2s infinite;
}

.cdk-overlay-pane .report-modal .cancel-button-container {
  display: flex;
}

@media (max-width: 1800px) {
  .c4p-flex-row {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

@keyframes pulse-icon {
  0% {
    transform: scale(0.99);
  }

  70% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.99);
  }
}

// .nav-invalid .mat-ink-bar {
//   background-color: var(--warning-border) !important;
// }

.c4p-link {
  color: #00afea;
  text-decoration: none;
}

.ql-toolbar.ql-snow {
  margin-top: 10px;
  border: 1px solid #eaeaea !important;
}

.ql-editor p {
  word-break: normal;
}

.ql-snow.ql-toolbar button {
  height: 20px !important;
  width: 24px !important;
  opacity: 0.8;
}

.ql-snow .ql-picker {
  height: 20px;
}

.ql-container.ql-snow {
  border: 1px solid #eaeaea !important;
  min-height: 100px;
  height: auto;
}

.c4p-small-label {
  vertical-align: middle;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 10px;
  opacity: 0.6;
  margin: 16px 0px 0px 16px;
  background-color: $form-bg-color;
}
.c4p-small-title-label {
  vertical-align: middle;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  opacity: 0.6;
  margin: 16px 0px 0px 16px;
  color: black;
}

.c4p-radio-button {
  margin: 5px;
}

.c4p-radio-button-question-set {
  margin-left: 10px;
  margin-bottom: 0;
  margin-top: 0;
}
.c4p-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.phone-column {
  flex-basis: 60;
  padding-right: 3px;
}

.email-column {
  flex-basis: 40;
  padding-left: 3px;
}

.c4p-empty-table-row {
  justify-content: center;
  font-style: italic;
  font-size: $font-normal;
  height: 45px;
  color: $form-font-color;
}
.c4p-tooltip-preline {
  white-space: pre-line !important;
}

.c4p-tooltip {
  position: absolute;
  font-size: $font-normal;
  color: $form-font-color;
  width: auto;
  padding: 8px;
  border-radius: 4px;
  /* Optional: Adjust shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-color: $card-line-color;
}

.c4p-tooltip-bg-default{
  background: $master-bg-color;
  color: $form-font-color;
}

.c4p-tooltip-bg-dark{
  background: #101828;
  color: #E3E4E5;
}

.c4p-tooltip:after {
  content: '';
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
}
.c4p-tooltip-top::after {
  border-width: 8px 8px 0 8px;
  border-color: #333 transparent transparent transparent;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.c4p-tooltip-bottom::after {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #333 transparent;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.c4p-tooltip-left::after {
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #333;
  top: 50%;
  right: -8px;
  transform: translateY(-30%);
}

.c4p-tooltip-right::after {
  border-width: 8px 8px 8px 0;
  border-color: transparent #333 transparent transparent;
  top: 50%;
  left: -8px;
  transform: translateY(-50%);
}
.c4p-tooltip-show {
  opacity: 1;
}

.c4p-widget-Small {
  width: 320px;
  height: 320px;
}
.c4p-widget-Medium {
  width: 652px;
  height: 320px;
}
.c4p-widget-Large {
  width: 652px;
  height: 652px;
}
.c4p-widget-Extra {
  width: 984px;
  height: 652px;
}
.c4p-widget-Fit {
  width: 100% !important;
  height: 100%;
  min-width: 120px;
  // min-height: 320px;
}
.c4p-widget-Full {
  width: unset;
  height: unset;
  min-width: 320px;
  min-height: 320px;
}
.c4p-widget-title {
  height: 12px;
  // background-color: $form-title-bg-color;
  color: $form-title-font-color;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  // border: solid;
  border-width: 0px;
  border-color: $form-title-line-color;
  border-bottom-width: 1px;
  font-size: $font-small;
  align-items: center;
  width: 100%;
  z-index: 100;
}
.c4p-widget-icons {
  font-size: 11px;
  width: 16px !important;
  height: unset !important;
  cursor: pointer;
  opacity: 0.7;
  // color: $form-font-color;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.c4p-widget-content {
  overflow-y: auto;
  overflow-x: auto;
  background-color: $form-title-bg-color;
  height: inherit;
}

.c4p-widget-icons:hover {
  opacity: 1;
  color: $sidebar-bg-color;
}

.c4p-widget {
  display: flex;
}

.c4p-widget-view {
  justify-content: center !important;
  text-align: center;
  min-width: 110px;
  width: 100%;
  padding: 8px;
}

.c4p-widget-view-no-padding {
  justify-content: center !important;
  text-align: left;
  min-width: 110px;
  width: 100%;
}

.c4p-widget-horizontal-line {
  border: solid;
  border-width: 1px 0px 0px 0px;
  border-color: $form-line-color;
  height: 0px;
  margin: 6px -8px;
}

.c4p-view-label {
  margin: 6px 0px 0px 0px;
  color: $form-font-color;
}

.c4p-view-value {
  margin: 6px 0px 0px 0px;
  font-size: 13px;
}

.panel-icon {
  font-size: 16px;
  padding-right: 5px;
  opacity: 0.4;
}

.fc {
  display: flex;
  flex-direction: column;
  font-size: 11px !important;
}

.fc .fc-toolbar-title {
  font-size: 13px !important;
  margin: 7px !important;
  text-align: center;
}

.fc-toolbar-chunk {
  display: flex !important;
}

.fc .fc-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: $button-bg-color !important;
  border: 1px solid $button-line-color !important;
  border-radius: 0.25em;
  display: inline-block;
  font-size: 12px !important;
  font-weight: 400;
  line-height: unset !important;
  padding: 3px !important;
  text-align: center;
  user-select: none;
  vertical-align: middle;
}

.fc .fc-button-primary:disabled {
  background-color: $button-bg-color !important;
  border-color: $button-line-color !important;
  color: $button-font-color !important;
}
.badge {
  display: inline-block;
  padding: 8px 10px;
  color: white;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fc .fc-button-active {
  background-color: $button-line-color !important;
  box-shadow: unset !important;
}

.fc .fc-view-harness {
  flex-grow: 1;
  position: relative;
  background-color: white !important;
}

.fc .fc-button .fc-icon {
  font-size: 13px !important;
  vertical-align: middle;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 4px !important;
}

.c4p-form-goals-container {
  overflow-y: scroll;
  height: auto;
  min-height: 30hv;
  padding: 10px;
  background-color: $form-bg-color;
}

.c4p-form-goals-popup-container {
  overflow-y: scroll;
  padding: 10px;
  background-color: $form-bg-color;
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

.c4p-object-list-multiple {
  font-family: $tuula-font-family;
  font-size: $font-normal;
  color: $info-color;
  background-color: $info-bg-color;
  border: 1px solid $info-border-color;
 border-radius: 16px;
  display: block;
  mix-blend-mode: multiply;
  padding: 4px 12px;
}

.c4p-object-list-menu {
  background-color: $sidebar-bg-color !important;
  border-radius: $border-radius !important;
}

.c4p-object-list-menu-item {
  background-color: $white !important;
  border-width: 0 0 1px 0;
  border-color: $sidebar-line-color;
  span{
    font-size: $font-normal;
    font-weight: 400;
    color: #000;
  }
}


// ***************************************************************************
// carefile - icom - form
// ***************************************************************************
.mat-column-icomSelectExternalProduct {
  flex: 0 0 15%;
}

.mat-column-icomSelectNewExternalProduct {
  flex: 0 0 15%;
}

.mat-column-icomSelectChangedExternalProduct {
  flex: 0 0 15%;
}

.mat-column-icomMissingData {
  flex: 0 0 20%;
}

.c4p-form-carefile-icom-error-flex {
  @extend .mat-error !optional;
  display: flex;
}

.c4p-form-carefile-icom-panel {
  max-width: 90vh;
}

@media screen and (max-width: 800px) {
  .c4p-tablet-screen {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .c4p-mobile-screen {
    display: none;
  }
}

.c4p-object-list-panel-header {
  color: $form-font-color;
  opacity: 0.6;
  font-family: $tuula-font-family;
  font-size: 10px;
}

.ngx-material-timepicker-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  border: none;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.3s;
  cursor: pointer;
  fill: var(--form-font-color);
  opacity: 0.6;
  width: 21px;
  height: 21px;
}

.c4p-row-title {
  display: inline-block;
  position: absolute;
  color: white;
  font-size: 10px;
  transform: rotate(-90deg);
  padding: 4px;
  margin-left: -24px;
  white-space: nowrap;
  width: 56px;
  text-align: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  word-wrap: normal;
}

.c4p-row-title-containter {
  min-height: 75px;
  margin-right: -16px;
  vertical-align: middle;
  min-width: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 45%);
  background-color: #a0a0a0;
  border-radius: 4px;
  position: relative;
  left: -18px;
  border-radius: 4px 0px 0px 4px;
}

.c4p-average-score {
  background-color: $button-bg-color;
  color: $button-font-color;
  font-family: $tuula-font-family;
  text-align: center;
  font-size: 24px;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.mat-mdc-list-base .mat-mdc-list-item,
.mat-mdc-list-base .mat-mdc-list-option {
  width: unset !important;
  padding: 6px 0px !important;
}

.spinner-item {
  display: grid;
  place-items: center;
  margin-top: 10px;
}

.x-large {
  width: 67vw;
}

.x-large-overflow {
  margin-top: 20px;
  width: 67vw;
  overflow: scroll;
  height: 50px !important;
}
.menuBtns .mat-mdc-menu-content {
  display: flex;
  flex-direction: column;
}

.menuBtns .mat-mdc-menu-content .c4p-button {
  margin: 0;
}

app-form-input-dropdown.internalProduct .c4p-form-input {
  align-items: flex-start !important;
}

.google-visualization-tooltip-item-list li:nth-child(1) {
  margin-bottom: -10px !important;
}

.google-visualization-tooltip-square {
  background-color: #ffffff !important;
}
.google-visualization-tooltip-square + span {
  color: #ffffff !important;
  display: none !important;
}

@media only screen and (max-width: 900px) {

  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
    .mat-expansion-panel-header:hover {
    background: none;
  }
}

@media only screen and (max-width: 1000px) {
  .c4p-popup-form {
    max-height: 80vh;
  }
  .c4p-form-questionset {
    display: contents;
  }
  .c4p-form-questionset .c4p-top-icons {
    display: none;
  }
  .c4p-form-questions-title {
    display: contents;
  }
  .c4p-content {
    overflow-y: hidden;
    padding-left: 0;
    padding-right: 0;
  }

  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
    .mat-expansion-panel-header:hover {
    background: none;
  }

  .c4p-table-view-wrap {
    min-width: 300px;
  }

  .multipleBtn .c4p-table-view-wrap .c4p-table-title-bar {
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    min-height: 95px;
  }

  .minh-130 .c4p-table-view-wrap .c4p-table-title-bar {
    min-height: 130px;
  }

  .multipleBtn .c4p-button {
    margin-bottom: 5px;
  }

  .multipleBtn .c4p-align-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }

  .multipleBtns .c4p-flex-title {
    flex-direction: column !important;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    height: 60px;
  }

  .c4p-grid {
    justify-content: center;
  }
  .w-large {
    width: 400px;
  }
  .cdk-overlay-pane .report-modal {
    max-height: 85vh;
    overflow: auto;
  }
  .cdk-overlay-pane .report-modal .cancel-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .c4p-form-question-answers-container {
    max-height: unset;
    overflow: unset;
  }
  .c4p-form-row-right {
    justify-content: start;
  }
}

.refferal-doc .c4p-content {
  padding: 0 !important;
}

[data-tippy-root] .tippy-content {
  max-height: 150px;
  overflow-y: auto;
}

.invalid-title {
  color: #f70000;
}

quill-editor {
  word-break: keep-all;
  width: -webkit-fill-available;
}

.disabled-inline-action {
  opacity: 0.5;
  pointer-events: none;
}

@media only screen and (min-height: 960px) {
  .c4p-form-question-answers-container {
    max-height: calc(100vh - 230px);
  }
  .c4p-form-questionset mat-table {
    max-height: unset;
  }
}

.ag-theme-alpine {
  --ag-odd-row-background-color: rgba(0, 81, 255, 0.07);
}

.ag-theme-balham {
  --ag-odd-row-background-color: rgb(0, 81, 255, 0.07);
}

.ag-theme-material {
  --ag-odd-row-background-color: rgb(0, 81, 255, 0.07);
}

@import './roboto.css';
@import './montserrat.css';

.cellCenter .ag-cell-wrapper {
  justify-content: center;
}

.mat-mdc-autocomplete-panel {
  background-color: $form-bg-color !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.update-medication-button {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}
mat-button-toggle.mat-button-toggle-checked span {
  margin-bottom: 60px;
  border-bottom: $sidebar-line-color solid !important;
}
.empty-table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
  margin-bottom: 160px;
}
.empty-table-on {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*carefile export*/
app-panel.bg-clr-white > div.c4p-popup-form {
  background-color: #fff;
}
app-panel-form-row.border-none > div.c4p-form-row {
  border: none;
}
app-panel-form-row.bottom {
  position: relative;
}

app-panel-form-row.bottom > div:before {
  content: '';
  position: absolute;
  left: 30px;
  bottom: 0;
  height: 15px;
  width: 90%;
  border-bottom: 1.5px dashed #a0a0a0;
}
.newdesign-label {
  margin-left: 30px;
  margin-top: 20px;
  background-color: #fff;
}
/*carefile export*/
// * Hour tooltip *//
.wide-tooltip .mdc-tooltip__surface {
  max-width: unset !important;
}
// * Hour tooltip *//

// notify -toastr
.toast-success  {
    background-color: $notify-bg-success !important;
    color : var(--primary-color-700) !important;
    background-image: url(/assets/svg/success.svg) !important;
    background-size: 35px !important;
  }
.toast-error{
  background-color: $notify-bg-error !important;
  color : var(--primary-color-700) !important;
  background-image: url(/assets/svg/error.svg) !important;
  background-size: 35px !important;
}
.toast-warning{
  background-color: $notify-bg-warning !important;
  color : var(--primary-color-700) !important;
  background-image: url(/assets/svg/warning.svg) !important;
  background-size: 35px !important;
}
.toast-info{
  background-color: $notify-bg-info !important;
  color : var(--primary-color-700) !important;
  background-image: url(/assets/svg/info.svg) !important;
  background-size: 35px !important;
}

//* Assesement-registrations,Budget-Utilization *//
app-assessment-registration > app-page > app-page-content > div.c4p-content > app-panel > div.c4p-form{
  box-shadow: none ;
}
app-budget-utilization > app-page > app-page-content > div.c4p-content > app-panel > div.c4p-form{
  box-shadow: none ;
}

app-configuration-main > app-page > app-page-content > div.c4p-content {
  height: 100%;
  overflow: hidden;
}
//* Assesement-registrations *//

.employee-create-container .c4p-form{
  background-color: white;
  box-shadow: none;
}

.tuula-section-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--gray-color-900);
  margin-top: 20px;
  margin-bottom: 28px;
  margin-left: 1px;
}
